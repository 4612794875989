import { createSlice } from '@reduxjs/toolkit';
import { createQuestionListAPI, deleteQuestionListAPI, getQuestionsListAPi, retrieveQuestionListAPI, updateQuestionListAPI } from 'src/service/QuestionService';

const initialState = {
    createModalOpen: false,
    allQuestions: [],
    validationErrors: {},
    showDrawer: false

};

export const QuestionManager = createSlice({
    name: 'Question',
    initialState,
    reducers: {
        openCreateModal: (state) => {
            state.createModalOpen = true;
        },
        closeCreateModal: (state) => {
            state.createModalOpen = false;
        },
        setAllQuestions: (state, action) => {
            state.allQuestions = action.payload;
        },
        setValidationErrors: (state, action) => {
            state.validationErrors = action.payload;
        },
        clearValidationErrors: (state) => {
            state.validationErrors = {};
        },
        addQuestion: (state, action) => {
            state.allQuestions = [action.payload?.data, ...state.allQuestions];
        },
        updateQuestion: (state, action) => {

            // Below code is to update the question in the array of questions
            const index = state.allQuestions.findIndex((obj) => obj.id === action.payload?.data?.id);
            state.allQuestions = [...state.allQuestions.slice(0, index), action.payload?.data, ...state.allQuestions.slice(index + 1)];
        },

        deleteQuestion: (state, action) => {
            const index = action.payload;
            state.allQuestions.splice(index, 1);
        },
        toggleQuestionDrawer: (state, action) => {
            state.showDrawer = action.payload;
        },
    },
});

export const {
    openCreateModal,
    closeCreateModal,
    setAllQuestions,
    setValidationErrors,
    clearValidationErrors,
    addQuestion,
    updateQuestion,
    deleteQuestion,
    toggleQuestionDrawer
} = QuestionManager.actions;

export const getQuestionListAction = (param) => async (dispatch) => getQuestionsListAPi(param);

export const retrieveQuestionAction = (param) => async (dispatch) => retrieveQuestionListAPI(param);

export const createQuestionAction = (param) => async (dispatch) => createQuestionListAPI(param);

export const updateQuestionAction = (param) => async (dispatch) => updateQuestionListAPI(param);

export const deleteQuestionAction = (id) => async (dispatch) => deleteQuestionListAPI(id);

export default QuestionManager.reducer;




