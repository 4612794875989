
import axios from 'axios';
import { REACT_APP_BACKEND_URL } from 'src/utils/constant';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.patch['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';

let headersList = { Accept: '*/*' };

export const RedeemCoinAPI = async (param) => {
    const reqOptions = {
        url: `${REACT_APP_BACKEND_URL}/cash-redeem/`,
        method: 'POST',
        headers: headersList,
        data: JSON.stringify({ ...param }),
    };
    const response = await axios.request(reqOptions);
    return response;
};

export const coinToCashAPI = async (param) => {
    const reqOptions = {
        url: `${REACT_APP_BACKEND_URL}/coin-to-cash-conversion`,
        method: 'POST',
        headers: headersList,
        data: JSON.stringify({ ...param }),
    };
    const response = await axios.request(reqOptions);
    return response;
};

export const VpaAPI = async (param) => {
    const { vpa } = param;  // Destructure the 'vpa' from the param object
    const reqOptions = {
        url: `${REACT_APP_BACKEND_URL}/validate-vpa/?vpa=${encodeURIComponent(vpa)}`,
        method: 'POST',
        headers: headersList,
        data: JSON.stringify({ ...param }), // If you need other params in the request body
    };

    const response = await axios.request(reqOptions);
    return response;
};