import { createSlice } from '@reduxjs/toolkit';
import {
  createSubTopicAPI,
  deleteSubTopicAPI,
  getSubTopicListAPI,
  retrieveSubTopicAPI,
  updateSubTopicAPI,
} from 'src/service/SubTopicService.js';

const initialState = {
  createModalOpen: false,
  allSubTopics: [],
  validationErrors: {},
};

export const SubTopicManager = createSlice({
  name: 'SubTopic',
  initialState,
  reducers: {
    openCreateModal: (state) => {
      state.createModalOpen = true;
    },
    closeCreateModal: (state) => {
      state.createModalOpen = false;
    },
    setAllSubTopics: (state, action) => {
      state.allSubTopics = action.payload;
    },
    setValidationErrors: (state, action) => {
      state.validationErrors = action.payload;
    },
    clearValidationErrors: (state) => {
      state.validationErrors = {};
    },
    addSubTopic: (state, action) => {
      state.allSubTopics = [action.payload, ...state.allSubTopics];
    },

    updateSubTopic: (state, action) => {
      // Below code is to update the question in the array of questions
      const index = state.allSubTopics.findIndex((obj) => obj.id === action.payload?.data?.id);
      state.allSubTopics = [...state.allSubTopics.slice(0, index), action.payload?.data, ...state.allSubTopics.slice(index + 1)];
    },
    deleteSubTopic: (state, action) => {
      const index = action.payload;
      state.allSubTopics.splice(index, 1);
    },
  },
});

export const {
  openCreateModal,
  closeCreateModal,
  setAllSubTopics,
  setValidationErrors,
  clearValidationErrors,
  addSubTopic,
  updateSubTopic,
  deleteSubTopic,
} = SubTopicManager.actions;

export const getSubTopicListAction = (param) => async (dispatch) => getSubTopicListAPI(param);

export const retrieveSubTopicAction = (id) => async (dispatch) => retrieveSubTopicAPI(id);

export const createSubTopicAction = (param) => async (dispatch) => createSubTopicAPI(param);

export const updateSubTopicAction = (param) => async (dispatch) => updateSubTopicAPI(param);

export const deleteSubTopicAction = (id) => async (dispatch) => deleteSubTopicAPI(id);

export default SubTopicManager.reducer;
