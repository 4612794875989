import axios from 'axios';
import { REACT_APP_BACKEND_URL } from 'src/utils/constant';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.patch['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';

let headersList = { Accept: '*/*' };

export const debitedcoinsAPI = async (date) => {
    const response = await axios.get(`${REACT_APP_BACKEND_URL}/debited-coins/`, { params: { date } });
    return response;
};

export const transactiondetailAPI = async (date) => {
    const response = await axios.get(`${REACT_APP_BACKEND_URL}/transaction-detail/`, { params: { date } });
    return response;
};

export const userdetailsAPI = async (date) => {
    const response = await axios.get(`${REACT_APP_BACKEND_URL}/user-detail/`, { params: { date } });
    return response;
};


export const creditedcoinsAPI = async (date) => {
    const response = await axios.get(`${REACT_APP_BACKEND_URL}/credited-coins/`, { params: { date } });
    return response;
};

export const userTransactionForAdminAPI = async (url) => {
    const response = await axios.get(url ?? `${REACT_APP_BACKEND_URL}/user-transaction-for-admin/`);
    return response;
};



