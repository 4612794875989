import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material";

const SchoolAddressDialog = ({ isOpen, onClose, onSubmit, schoolAddress, setSchoolAddress, formik }) => {
    const handleSchoolAddressSubmit = () => {
        onSubmit(schoolAddress); // Call your onSubmit function to save the school address
        onClose(); // Close the school address dialog
    };

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>School Address Details</DialogTitle>
            <DialogContent>
                {/* Add form fields for school address details */}
                <Grid container gap={2} marginTop={2}>
                    <TextField
                        label="School Address Line 1"
                        fullWidth
                        value={formik?.values?.school_address_line_1}
                        onChange={formik.handleChange("school_address_line_1")}
                    />
                    <TextField
                        label="School Address Line 2"
                        fullWidth
                        value={formik?.values?.school_address_line_2}
                        onChange={formik.handleChange("school_address_line_2")}
                    />
                    <TextField
                        label="School City"
                        fullWidth
                        value={formik?.values?.school_city}
                        onChange={formik.handleChange("school_city")}
                    />
                    <TextField
                        label="School State"
                        fullWidth
                        value={formik?.values?.school_state}
                        onChange={formik.handleChange("school_state")}
                    />
                    <TextField
                        label="School Country"
                        fullWidth
                        value={formik?.values?.school_country}
                        onChange={formik.handleChange("school_country")}
                    />
                    <TextField
                        label="School Postal Code"
                        fullWidth
                        value={formik?.values?.school_postal_code}
                        onChange={formik.handleChange("school_postal_code")}
                    />
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSchoolAddressSubmit} color="primary">
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default SchoolAddressDialog;