import { createSlice } from '@reduxjs/toolkit';

// import {
//     createUserAPI,
//     deleteUserAPI,
//     getUserListAPI,
//     retrieveUserAPI,
//     updateUserAPI,
// } from '/src/service/UserManagerService.js';


const initialState = {
    allUser: [],
    avatar: '',
    email: '',
    first_name: '',
    last_name: '',
    user_type: 0,
    phone_number: '',
    is_email_verified: false,
    is_phone_number_verified: false,
    features: [],
    settings: {},
    institute: {},
    is_active: false,
    is_admin: false,
};

export const UserInfo = createSlice({
    name: 'UserInfo',
    initialState,
    reducers: {
        setUserData: (state, action) => {
            state.allUser = action.payload;
        },
        setEmailVerified: (state, action) => {
            state.is_email_verified = action.payload;
        },
        setPhoneVerified: (state, action) => {
            state.is_phone_number_verified = action.payload;
        },
    },
});

// // Export the actions for external use
// export const { setUserData, setEmailVerified, setPhoneVerified } = UserInfo.actions;

// export const getUserListAction = (param) => async (dispatch) => getUserListAPI(param);

// export const retrieveUserAction = (id) => async (dispatch) => retrieveUserAPI(id);

// export const createUserAction = (param) => async (dispatch) => createUserAPI(param);

// export const updateUserAction = (param) => async (dispatch) => updateUserAPI(param);

// export const deleteUserAction = (id) => async (dispatch) => deleteUserAPI(id);

export default UserInfo.reducer;
