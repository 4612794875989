

import axios from 'axios';
import { REACT_APP_BACKEND_URL } from 'src/utils/constant';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.patch['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';

let headersList = { Accept: '*/*' };

/**
 * The function is an asynchronous API call to retrieve a user list based on the given parameter.
 *
 * Args:
 *   param: The `param` parameter is an object that contains any additional parameters or filters that you want to pass to
 * the API endpoint.
 */
export const getPaperGeneratorListAPI = async (url) => {
    const response = await axios.get(url ?? `${REACT_APP_BACKEND_URL}/api/question-papers/`);
    return response;
};

export const getAttemptedPaperListAPI = async (url) => {
    const response = await axios.get(url ?? `${REACT_APP_BACKEND_URL}/api/attempted-papers/`);
    return response;
};



/**
 * The function retrieves a PaperGenerator API based on the provided ID.
 */
export const retrievePaperGeneratorAPI = async (id) => {
    const reqOptions = {
        url: `${REACT_APP_BACKEND_URL}/api/question-papers/${id}/`,
        method: 'GET',
        headers: headersList,
    };
    // 

    const response = await axios.request(reqOptions);
    return response;
};

export const retrievePaperBasicInfoAPI = async (id) => {
    const reqOptions = {
        url: `${REACT_APP_BACKEND_URL}/paper-info/?id=${id}`,
        method: 'GET',
        headers: headersList,
    };
    // 
    const response = await axios.request(reqOptions);
    return response;
};

export const retrievePaperInfoAPI = async (id) => {
    const reqOptions = {
        url: `${REACT_APP_BACKEND_URL}/paper-info/${id}/`,
        method: 'GET',
        headers: headersList,
    };
    const response = await axios.request(reqOptions);
    return response;
};

export const retrieveAnalysisGeneratorAPI = async (id) => {
    const reqOptions = {
        url: `${REACT_APP_BACKEND_URL}/api/paper-attempted-question-wise-result/${id}`,
        method: 'GET',
        headers: headersList,
    };


    const response = await axios.request(reqOptions);
    return response;
};
export const paperAttemptAPI = async (param) => {
    const reqOptions = {
        url: `${REACT_APP_BACKEND_URL}/api/test-attempts/`,
        method: 'POST',
        headers: headersList,
        data: JSON.stringify({ ...param }),

    };
    const response = await axios.request(reqOptions);
    return response;
};

/**
 * The function creates a PaperGenerator API using the provided parameter.
 *
 * Args:
 *   param: The `param` parameter is an object that contains the necessary information to create a PaperGenerator API.
 */
export const createPaperGeneratorAPI = async (param) => {
    const reqOptions = {
        url: `${REACT_APP_BACKEND_URL}/api/question-papers/`, // Adjust the URL
        method: 'POST',
        headers: headersList,
        data: JSON.stringify({ ...param }),
    };
    const response = axios.request(reqOptions);
    return response;
    // Dispatch a success action
};

export const calculateCoinRequiredAPI = async (param) => {
    const reqOptions = {
        url: `${REACT_APP_BACKEND_URL}/calculate-coins-required/`, // Adjust the URL
        method: 'POST',
        headers: headersList,
        data: JSON.stringify({ ...param }),
    };
    const response = axios.request(reqOptions);
    return response;
    // Dispatch a success action
};
export const specialRequiredAPI = async (param) => {
    const reqOptions = {
        url: `${REACT_APP_BACKEND_URL}/exam/`, // Adjust the URL
        method: 'POST',
        headers: headersList,
        data: JSON.stringify({ ...param }),
    };
    const response = axios.request(reqOptions);
    return response;
    // Dispatch a success action
};

/**
 * The function "updatePaperGeneratorAPI" is an asynchronous function that takes a parameter and performs some action.
 *
 * Args:
 *   param: The `param` parameter is an object that contains the necessary information to update a PaperGenerator API.
 */
export const updatePaperGeneratorAPI = async (param) => {
    const { id, values } = param;
    let reqOptions = {
        url: `${REACT_APP_BACKEND_URL}/api/question-papers/${id}/`,
        method: 'PATCH',
        headers: headersList,
        data: JSON.stringify({ ...values }),
    };

    return await axios.request(reqOptions);
};

/**
 * The deletePaperGeneratorAPI function is an asynchronous function that takes an object with an "id" property as a parameter and
 * deletes the corresponding data from the API.
 */
export const deletePaperGeneratorAPI = async (param) => {
    const { id } = param;
    let reqOptions = {
        url: `${REACT_APP_BACKEND_URL}/api/question-papers/${id}/`,
        method: 'DELETE',
        headers: headersList,
    };
    return await axios.request(reqOptions);
};

