import { createSlice } from '@reduxjs/toolkit';
import {
	createImageAPI,
	// deleteImageAPI,
	// getImageListAPI,
	// retrieveImageAPI,
	// updateImageAPI,
} from 'src/service/ImageService.js';

const initialState = {
	createModalOpen: false,
	allImages: [],
	validationErrors: {},
};

export const Image = createSlice({
	name: 'Image',
	initialState,
	reducers: {
		openCreateModal: (state) => {
			state.createModalOpen = true;
		},
		closeCreateModal: (state) => {
			state.createModalOpen = false;
		},
		setAllImages: (state, action) => {
			state.allImages = action.payload;
		},
		setValidationErrors: (state, action) => {
			state.validationErrors = action.payload;
		},
		clearValidationErrors: (state) => {
			state.validationErrors = {};
		},
		addImage: (state, action) => {
			state.allImages.push(action.payload);
		},
		updateImage: (state, action) => {
			const { index, values } = action.payload;
			state.allImages[index] = { ...state.allImages[index], ...values };
		},
		deleteImage: (state, action) => {
			const index = action.payload;
			state.allImages.splice(index, 1);
		},
	},
});

export const {
	openCreateModal,
	closeCreateModal,
	setAllImages,
	setValidationErrors,
	clearValidationErrors,
	addImage,
	updateImage,
	deleteImage,
} = Image.actions;

// export const getImageListAction = (param) => async (dispatch) => getImageListAPI(param);

// export const retrieveImageAction = (id) => async (dispatch) => retrieveImageAPI(id);

export const createImageAction = (param) => async (dispatch) => createImageAPI(param);

// export const updateImageAction = (param) => async (dispatch) => uploadImageAPI(param);

// export const deleteImageAction = (id) => async (dispatch) => deleteImageAPI(id);

export default Image.reducer;
