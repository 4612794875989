import { createSlice } from '@reduxjs/toolkit';
import { createSpecificPaperAPI, registerSpecialPaperUserAPI, retrieveSpecialPaperMetaDataAPI, submitSpecificPaperAPI, verifySpecialPaperUserAPI } from 'src/service/SpecialPaperService';

const viewSpecificQuestion = (state, action) => {
    state.currentIndex = action.payload;
    const qState = state.questionList[action.payload]?.state === 0 ? 4 : state.questionList[action.payload]?.state
    state.questionList[action.payload] = { ...state.questionList[action.payload], state: qState }
}

const initialState = {
    paperId: '',
    userFirstName: '',
    userLastName: '',
    userEmail: '',
    userId: '',
    mainPaperId: "",
    paperData: {},
    questionList: [],
    isDrawerOpen: false,
    isInstructionVisible: false,
    isQuestionPaperVisible: false,
    currentIndex: 0,
    metaData: {},
    result: [],
    Section: [],
    SectionIndex: {
        SectionStartIndex: undefined,
        StartSectionName: "",
        SectionEndIndex: undefined,
        EndSectionName: "",

    },
    questionStateColor: {
        0: {
            color: '#000',
            background: '#f9f9f9',
        },
        1: {
            borderRadius: '20px 20px 0 0',
            background: '#27ae60',
            color: '#fff',
            borderColor: '#27ae60',
        },
        2: {
            background: '#9b59b6',
            borderRadius: '24px',
            borderColor: '#9b59b6',
            color: '#fff',
        },
        3: {
            background: '#9b59b6',
            borderRadius: '24px',
            borderColor: '#9b59b6',
            color: '#fff',
            after: {
                content: '',
                position: 'absolute',
                borderColor: '#27ae60',
                borderWidth: 'solid',
                border: '0 0.3em 0.3em 0',
                height: ' 1em',
                top: '-0.375em',
                right: '0',
                transform: 'rotate(45deg)',
                width: '0.5em',
            }
        },
        4: {
            borderRadius: '0 0 20px 20px',
            background: '#c0392b',
            borderColor: '#c0392b',
            color: '#fff',
        }
    },
};

export const SpecialPaperManager = createSlice({
    name: 'SpecialPaper',
    initialState,
    reducers: {
        setPaperId: (state, action) => {
            state.paperId = action.payload;
        },
        setUserId: (state, action) => {
            state.userId = action.payload;
        },
        setUserFirstName: (state, action) => {
            state.userFirstName = action.payload;
        },
        setUserLastName: (state, action) => {
            state.userLastName = action.payload;
        },
        setMainPaperId: (state, action) => {
            state.mainPaperId = action.payload;
        },
        setMetaData: (state, action) => {
            state.metaData = action.payload;
        },
        setPaperData: (state, action) => {

            state.paperData = action.payload
            // 0: Not Visited, 1: Answered, 2:Marked 3:Marked and answered 4:Not Answered
            state.questionList = action.payload?.questions?.map(item => ({ ...item, state: 0, timeSpent: 0 }))
        },
        setUserEmail: (state, action) => {
            state.userEmail = action.payload;
        },


        setPaperSection: (state, action) => {
            state.Section = action.payload.map(item => ({ ...item }))
        },
        setQuestionList: (state, action) => {
            state.questionList = action.payload
        },

        setQuestionAnswerSelection: (state, action) => {
            const { index, currentQuestion, option } = action.payload;
            state.questionList[index] = {
                ...currentQuestion,
                selectedOption: option,
                state: 1,
            }
        },
        setOrQuestionAnswerSelection: (state, action) => {
            const { index, currentQuestion, orIndex, orques, option } = action.payload;

            const updatedOrQuestions = currentQuestion.or_questions.map((orQuestion, i) => {
                if (i === orIndex) {
                    return {
                        ...orQuestion,
                        selectedOption: option
                    };
                }
                return {
                    ...orQuestion,
                    selectedOption: {} // Set selectedOption to an empty object for other or_questions
                };
            });

            state.questionList[index] = {
                ...currentQuestion,
                state: 1,
                selectedOption: {}, // Set selectedOption to an empty object for the main question
                or_questions: updatedOrQuestions,
            };
        },
        setClearResponse: (state, action) => {
            const { index, currentQuestion, } = action.payload;

            state.questionList[index] = {
                ...currentQuestion,
                selectedOption: {},
                state: 4,
            }
        },
        setQuestionTime: (state, action) => {
            const t = action.payload.currentQuestion.timeSpent + action.payload?.time;
            state.questionList[action.payload?.index] = {
                ...action.payload?.currentQuestion,
                timeSpent: t
            }
        },

        moveToNextQuestion: (state, action) => {
            if (action?.payload < state.questionList.length) {
                viewSpecificQuestion(state, action)
            }
        },
        moveToPreviousQuestion: (state, action) => {
            if (action?.payload >= 0) {
                viewSpecificQuestion(state, action)
            }
        },

        setViewSpecificQuestion: (state, action) => {
            viewSpecificQuestion(state, action)
        },
        // 0: Not Visited, 1: Answered, 2:Marked 3:Marked and answered 4:Not Answered
        setMarkedQuestion: (state, action) => {
            const t = action.payload.currentQuestion.timeSpent + action.payload?.time;
            let qState;

            // const qState = action.payload.currentQuestion.state === 4 ? 2 : 3
            if (action.payload.currentQuestion.state == 4) {
                qState = 2;
            } else if (action.payload.currentQuestion.state == 1) {
                qState = 3;
            } else if (action.payload.currentQuestion.state == 2) {
                qState = 2
            } else if (action.payload.currentQuestion.state == 3) {
                qState = 3
            }
            // 

            state.questionList[action.payload.index] = { ...action.payload.currentQuestion, state: qState, timeSpent: t }
        },


        setEmptyPaperData: (state) => {
            state.paperData = state.paperData.length(0);
        },

        setemptyQuestionList: (state) => {
            state.questionList = state.questionList.length(0);
        }
        ,
        setDrawerOpen: (state) => {
            state.isDrawerOpen = !state.isDrawerOpen;
        },
        setInstructionVisible: (state, action) => {
            state.isInstructionVisible = action.payload;
        },
        setQuestionPaperVisible: (state, action) => {
            state.isQuestionPaperVisible = action.payload;
        },
        setResult: (state, action) => {
            state.result = action.payload;
        },
        setSectionIndex: (state, action) => {
            state.SectionIndex.SectionStartIndex = action.payload.startIndex;
            state.SectionIndex.SectionEndIndex = action.payload.endIndex;
            state.SectionIndex.StartSectionName = action.payload.StartSectionName;
            state.SectionIndex.EndSectionName = action.payload.EndSectionName;

        }
    },
});

export const {
    setPaperId,
    setUserFirstName,
    setUserLastName,
    setUserId,
    setMainPaperId,
    setMetaData,
    setPaperData,
    setUserEmail,
    setClearResponse,
    setMarkedQuestion,
    setQuestionAnswerSelection,
    setOrQuestionAnswerSelection,
    setSectionIndex,
    setPaperSection,
    setEmptyPaperData,
    setQuestionList,
    setemptyQuestionList,
    setDrawerOpen,
    setInstructionVisible,
    setQuestionPaperVisible,
    setisAnwered,
    setViewSpecificQuestion,
    setisMarked,
    setisMarkedandAnswred,
    setisNotVisited,
    removeisMarked,
    moveToNextQuestion,
    moveToPreviousQuestion,
    setResult,
    setQuestionTime
} = SpecialPaperManager.actions;

export default SpecialPaperManager.reducer;

export const createSpecificPaperAction = (param) => async (dispatch) => createSpecificPaperAPI(param);
export const submitSpecificPaperAction = (param) => async (dispatch) => submitSpecificPaperAPI(param);
export const retrieveSpecialPaperMetaDataAction = (id) => async (dispatch) => retrieveSpecialPaperMetaDataAPI(id);
export const registerSpecialPaperUserAction = (param) => async (dispatch) => registerSpecialPaperUserAPI(param);
export const verifySpecialPaperUserAction = (param) => async (dispatch) => verifySpecialPaperUserAPI(param);