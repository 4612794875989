
import { createSlice } from '@reduxjs/toolkit';
import { coinToCashAPI, RedeemCoinAPI, VpaAPI } from 'src/service/UpiService';

const initialState = {
};

export const UpiReducer = createSlice({
    name: 'UPI',
    initialState,
    reducers: {

    },
});

export const {
} = UpiReducer.actions;

export const RedeemCashAPIAction = (param) => async (dispatch) => RedeemCoinAPI(param);
export const coinToCashAPIAction = (param) => async (dispatch) => coinToCashAPI(param);
export const VpaAPIAction = (param) => async (dispatch) => VpaAPI(param);

export default UpiReducer.reducer;