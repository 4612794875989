import { createSlice } from '@reduxjs/toolkit';
import {
    createNotesAPI,
    downloadFreeNotesAPI,
    downloadPaidNotesAPI,
    getNoteAPI,
    getPurchaseNotesListAPI,
    getTeacherNotesListAPI,
    purchaseNotesAPI,
    updateNotesAPI,
    uploadFreeNotesAPI,
    uploadNotesAPI,
    uploadNotesImageAPI
} from 'src/service/NotesService';



const initialState = {
    allNotes: [],
    allTeacherNotes: [],
    allPerchasedNotes: [],
    filters: {
        category: 'standard',
    },
    screen: 'allNotes',
    indivisualNote: {}
};

export const NotesReducer = createSlice({
    name: 'Notes',
    initialState,
    reducers: {
        setAllNotes: (state, action) => {
            state.allNotes = action.payload;
        },
        setIndivisualNote: (state, action) => {
            state.indivisualNote = action.payload;
        },
        setFilter(state, action) {
            state.filters.category = action.payload.category;
        },
        setAllTeacherNotes: (state, action) => {
            state.allTeacherNotes = action.payload;
        },
        setAllPerchasedNotes: (state, action) => {
            state.allPerchasedNotes = action.payload;
        },
        setScreen: (state, action) => {
            state.screen = action.payload;
        },
        addNotes: (state, action) => {
            state.allNotes = [action.payload, ...state.allNotes];
        },
        addTeacherNotes: (state, action) => {
            state.allTeacherNotes = [action.payload, ...state.allTeacherNotes];
        },
        addPerchasedNotes: (state, action) => {
            state.allNotes = [action.payload, ...state.allNotes];
        },
        updateNote: (state, action) => {
            const updatedNote = action.payload?.data;
            const index = state.allNotes.findIndex((obj) => obj.id === updatedNote.id);
            if (index !== -1) {
                // Replace the old note with the updated one
                state.allNotes[index] = updatedNote;
            }
        },
    },
});

export const {
    addNotes,
    setAllNotes,
    setAllTeacherNotes,
    setAllPerchasedNotes,
    addTeacherNotes,
    updateNote,
    setFilter,
    setIndivisualNote,
    setScreen
} = NotesReducer.actions;

export const getTeacherNotesListAction = (param) => async (dispatch) => getTeacherNotesListAPI(param);
export const getPurchaseNotesListAction = (param) => async (dispatch) => getPurchaseNotesListAPI(param);
export const getNoteAction = (param) => async (dispatch) => getNoteAPI(param);
export const downloadFreeNotesAction = (id) => async (dispatch) => downloadFreeNotesAPI(id);
export const downloadPaidNotesAction = (id) => async (dispatch) => downloadPaidNotesAPI(id);
export const purchaseNotesAction = (param) => async (dispatch) => purchaseNotesAPI(param);
export const updateNotesAction = (param) => async (dispatch) => updateNotesAPI(param);
export const uploadFreeNotesAction = (param) => async (dispatch) => uploadFreeNotesAPI(param);
export const uploadNotesAction = (param) => async (dispatch) => uploadNotesAPI(param);
export const createNotesAction = (param) => async (dispatch) => createNotesAPI(param);
export const uploadNotesImage = (param) => async (dispatch) => uploadNotesImageAPI(param);



export default NotesReducer.reducer;

