import axios from 'axios';
import { REACT_APP_BACKEND_URL } from 'src/utils/constant';

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.patch['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';

let headersList = { Accept: '*/*' };

export const getBatchListAPI = async (url) => {
    const response = await axios.get(url ?? `${REACT_APP_BACKEND_URL}/batches/`);
    return response;
};

export const createBatchAPI = async (param) => {
    const reqOptions = {
        url: `${REACT_APP_BACKEND_URL}/batches/`,
        method: 'POST',
        headers: headersList,
        data: JSON.stringify({ ...param }),
    };

    const response = await axios.request(reqOptions);
    return response;
};

export const retrieveBatchAPI = async (id) => {
    const reqOptions = {
        url: `${REACT_APP_BACKEND_URL}/batches/${id}/`,
        method: 'GET',
        headers: headersList,
    };

    const response = await axios.request(reqOptions);
    return response;
};

export const updateBatchAPI = async (param) => {
    const { id, values } = param;
    const reqOptions = {
        url: `${REACT_APP_BACKEND_URL}/batches/${id}/`,
        method: 'PUT',
        headers: headersList,
        data: JSON.stringify({ ...values }),
    };

    return await axios.request(reqOptions);
};

export const partialUpdateBatchAPI = async (param) => {
    const { id, values } = param;
    const reqOptions = {
        url: `${REACT_APP_BACKEND_URL}/batches/${id}/`,
        method: 'PATCH',
        headers: headersList,
        data: JSON.stringify({ ...values }),
    };

    return await axios.request(reqOptions);
};

export const deleteBatchAPI = async (id) => {
    const reqOptions = {
        url: `${REACT_APP_BACKEND_URL}/batches/${id}/`,
        method: 'DELETE',
        headers: headersList,
    };

    return await axios.request(reqOptions);
};

// export const getBatchPaperListAPI = async (id) => {
//     const response = await axios.get(`${REACT_APP_BACKEND_URL}/batch-paper/?batch_id=${id}`);
//     return response;
// };

export const getBatchPaperListAPI = async (id) => {
    const reqOptions = {
        //   url: ${REACT_APP_BACKEND_URL}/batch-paper/?batch_id=${id},
        //   method: 'GET',
        //   headers: headersList,
        url: `${REACT_APP_BACKEND_URL}/batch-paper/?batch_id=${id}`,
        method: 'GET',
        headers: headersList,
    };
    const response = await axios.request(reqOptions);
    return response;
};

export const createBatchPaperAPI = async (id, param) => {
    const reqOptions = {
        url: `${REACT_APP_BACKEND_URL}/batch-paper/?batch_id=${id}`,
        method: 'POST',
        headers: headersList,
        data: JSON.stringify({ ...param }),
    };

    const response = await axios.request(reqOptions);
    return response;
};

export const getBatchRequestListAPI = async (id) => {
    const response = await axios.get(`${REACT_APP_BACKEND_URL}/batch-requests/`);
    return response;
};

export const createBatchRequestAPI = async (param) => {
    const reqOptions = {
        url: `${REACT_APP_BACKEND_URL}/batch-requests/`,
        method: 'POST',
        headers: headersList,
        data: JSON.stringify({ ...param }),
    };

    const response = await axios.request(reqOptions);
    return response;
};
export const getStudentRequestListAPI = async (id) => {
    const reqOptions = {
        url: `${REACT_APP_BACKEND_URL}/batch-requests/?batch_id=${id}`,
        method: 'GET',
        headers: headersList,
    };

    const response = await axios.request(reqOptions);
    return response;
};

export const updateStudentRequest = async (param) => {
    const { id, value } = param;

    let reqOptions = {
        url: `${REACT_APP_BACKEND_URL}/batch-requests/${id}/`,
        method: 'PATCH',
        headers: headersList,
        data: JSON.stringify({ ...value }),
    };
    return await axios.request(reqOptions);
}

export const getBatchNotesListAPI = async (id) => {
    const reqOptions = {
        url: `${REACT_APP_BACKEND_URL}/batch-notes/?batch_id=${id}`,
        method: 'GET',
        headers: headersList,
    };
    const response = await axios.request(reqOptions);
    return response;
};

