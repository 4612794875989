import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material";

const AddressDialog = ({ isOpen, onClose, onSubmit, address, setAddress, formik }) => {
    const handleAddressSubmit = () => {
        onSubmit(address); // Call your onSubmit function to save the address
        onClose(); // Close the address dialog
    };

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>Address Details</DialogTitle>
            <DialogContent>
                {/* Add form fields for address details */}
                <Grid container gap={2} marginTop={2}>
                    <TextField
                        label="Address Line 1"
                        fullWidth
                        value={formik?.values?.address_line_1}
                        onChange={formik.handleChange("address_line_1")}
                    />
                    <TextField
                        label="Address Line 2"
                        fullWidth
                        value={formik?.values?.address_line_2}
                        onChange={formik.handleChange("address_line_2")}
                    />
                    <TextField
                        label="City"
                        fullWidth
                        value={formik?.values?.city}
                        onChange={formik.handleChange("city")}
                    />
                    <TextField
                        label="State"
                        fullWidth
                        value={formik?.values?.state}
                        onChange={formik.handleChange("state")}
                    />
                    <TextField
                        label="Country"
                        fullWidth
                        value={formik?.values?.country}
                        onChange={formik.handleChange("country")}
                    />
                    <TextField
                        label="Postal Code"
                        fullWidth
                        value={formik?.values?.postal_code}
                        onChange={formik.handleChange("postal_code")}
                    />
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleAddressSubmit} color="primary">
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddressDialog;