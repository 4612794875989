import { createSlice } from '@reduxjs/toolkit';
import {
  createRoleAPI,
  createUserAPI,
  deleteUserAPI,
  getBasicUserListAPI,
  getUserCashDetailsListAPI,
  getUserFeedbackDetailsListAPI,
  getUserInstituteFeatureAPI,
  getUserListAPI,
  getUserPermissionAPI,
  getUserRoleAPI,
  retrieveUserAPI,
  updateAdminUserAPI,
  updateRoleAPI,
  updateUserAPI,
  verifyPhoneNumberEmail,
} from 'src/service/UserManagerService';

const initialState = {
  createModalOpen: false,
  allUsers: [],
  allFeature: [],
  allRoles: [],
  allPermission: [],
  validationErrors: {},
  allUsersCashDetails: []
};

export const UserManager = createSlice({
  name: 'User',
  initialState,
  reducers: {
    openCreateModal: (state) => {
      state.createModalOpen = true;
    },
    closeCreateModal: (state) => {
      state.createModalOpen = false;
    },
    setAllUsers: (state, action) => {
      //
      state.allUsers = action.payload;
    },
    setAllUsersCashDetails: (state, action) => {
      //
      state.allUsersCashDetails = action.payload;
    },
    setAllFeature: (state, action) => {
      //
      state.allFeature = action.payload;
    },
    setAllRole: (state, action) => {
      //
      state.allRoles = action.payload;
    },
    setAllPermission: (state, action) => {
      //
      state.allPermission = action.payload;
    },
    setValidationErrors: (state, action) => {
      state.validationErrors = action.payload;
    },
    clearValidationErrors: (state) => {
      state.validationErrors = {};
    },
    addUsers: (state, action) => {
      state.allUsers.push(action.payload);
    },
    addRole: (state, action) => {
      state.allRoles.push(action.payload);
    },
    // updateUsers: (state, action) => {
    //   const { index, values } = action.payload;
    //   state.allUsers[index] = { ...state.allUsers[index], ...values };
    // },
    updateUsers: (state, action) => {

      // Below code is to update the question in the array of questions
      const index = state.allUsers.findIndex((obj) => obj.id === action.payload?.data?.id);
      state.allUsers = [...state.allUsers.slice(0, index), action.payload?.data, ...state.allUsers.slice(index + 1)];
    },
    updateRoles: (state, action) => {

      // Below code is to update the question in the array of questions
      const index = state.allRoles.findIndex((obj) => obj.id === action.payload?.data?.id);
      state.allRoles = [...state.allRoles.slice(0, index), action.payload?.data, ...state.allRoles.slice(index + 1)];
    },
    deleteUsers: (state, action) => {
      const index = action.payload;
      state.allUsers.splice(index, 1);
    },
    updateProfile: (state, action) => {
      const { values } = action.payload;
      // Update the user's profile data in the state
      state.allUsers = state.allUsers.map((user) =>
        user.id === values.user_id ? { ...user, ...values } : user
      );
    },
  },
});

export const {
  addRole,
  openCreateModal,
  closeCreateModal,
  setAllUsers,
  setAllUsersCashDetails,
  setValidationErrors,
  clearValidationErrors,
  addUsers,
  updateUsers,
  deleteUsers,
  updateProfile,
  setAllFeature,
  setAllRole,
  setAllPermission,
  updateRoles,
} = UserManager.actions;

export const getUserListAction = (param) => async (dispatch) => getUserListAPI(param);
export const getUserCashDetailsListAction = (param) => async (dispatch) => getUserCashDetailsListAPI(param);

export const getUserFeedbackDetailsListAction = (param) => async (dispatch) => getUserFeedbackDetailsListAPI(param);

export const getBasicUserListAction = (param) => async (dispatch) => getBasicUserListAPI(param);

export const getUserInstituteFeatureAction = (param) => async (dispatch) => getUserInstituteFeatureAPI(param);

//Role
export const getUserRoleAction = (param) => async (dispatch) => getUserRoleAPI(param);

export const getUserPermissionAction = (param) => async (dispatch) => getUserPermissionAPI(param);

export const updateRoleAction = (param) => async (dispatch) => updateRoleAPI(param);
export const createRoleAction = (param) => async (dispatch) => createRoleAPI(param);


export const retrieveUserAction = (id) => async (dispatch) => retrieveUserAPI(id);

export const createUserAction = (param) => async (dispatch) => createUserAPI(param);

export const updateUserAction = (param) => async (dispatch) => updateUserAPI(param);

export const updateAdminUserAction = (param) => async (dispatch) => updateAdminUserAPI(param);

export const deleteUserAction = (id) => async (dispatch) => deleteUserAPI(id);

export const userEmailPhoneVerify = (param) => async (dispatch) => verifyPhoneNumberEmail(param);
export default UserManager.reducer;
