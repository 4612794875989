
import { createSlice } from '@reduxjs/toolkit';
import { getLeaderBoardListAPI, getPaperLeaderBoardListAPI } from 'src/service/GeneralApis';

const initialState = {
    leaderBoardData: [],
    paperLeaderBoardData: []
};

export const LeaderBoardReducer = createSlice({
    name: 'LeaderBoard',
    initialState,
    reducers: {
        setleaderBoardData: (state, action) => {
            state.leaderBoardData = action.payload;
        },
        setPaperLeaderBoardData: (state, action) => {
            state.paperLeaderBoardData = action.payload;
        },
    },
});

export const {
    setleaderBoardData,
    setPaperLeaderBoardData
} = LeaderBoardReducer.actions;

export const getLeaderBoardListAction = (param) => async (dispatch) => getLeaderBoardListAPI(param);
export const getPaperLeaderBoardListAction = (param) => async (dispatch) => getPaperLeaderBoardListAPI(param);

export default LeaderBoardReducer.reducer;