import { createSlice } from '@reduxjs/toolkit';
import { RedeemCoinsAPI } from 'src/service/GeneralApis';

import { AdminTransferCoinsAPI, ClaimFriendAPI, PaymentValidationAPI, TransferCoinsAPI, createdPurchaseAPI, getCreditsHistoryListAPI, getCreditsListAPI, getRedeemHistoryListAPI, getTransactionHistoryListAPI } from 'src/service/PaymentService';

const initialState = {
    coins: 0,
    cash: 0,
    creditHistory: {},
    completeCreditHistory: [],
};

export const Payment = createSlice({
    name: 'Payment',
    initialState,
    reducers: {
        setRequestType: (state, action) => {
            state.requestType = action.payload;
            state.signup = 1
        },
        setCoins: (state, action) => {
            state.coins = action.payload;

        },
        setCash: (state, action) => {
            state.cash = action.payload;

        },
        setCreditHistory: (state, action) => {
            state.creditHistory = action.payload;
        },
        setCompleteCreditHistory: (state, action) => {
            state.completeCreditHistory = action.payload;
        },

    },
});
export const getCreditAPIAction = (param) => async (dispatch) => getCreditsListAPI(param);
export const getCreditHistoryAPIAction = (param) => async (dispatch) => getCreditsHistoryListAPI(param);

export const getTransactionHistoryAPIAction = (param) => async (dispatch) => getTransactionHistoryListAPI(param);
export const getRedeemHistoryAPIAction = (param) => async (dispatch) => getRedeemHistoryListAPI(param);
export const createdPurchaseAPIAction = (param) => async (dispatch) => createdPurchaseAPI(param);
export const claimFriendAPIAPIAction = (param) => async (dispatch) => ClaimFriendAPI(param);
export const PaymentValidationAPIAction = (param) => async (dispatch) => PaymentValidationAPI(param);

export const transferCoinsAPIAction = (param) => async (dispatch) => TransferCoinsAPI(param);
export const admintransferCoinsAPIAction = (param) => async (dispatch) => AdminTransferCoinsAPI(param);
export const RedeemCoinsAPIAction = (param => async (dispatch) => RedeemCoinsAPI(param))


// ClaimFriendAPI
export const {
    setCompleteCreditHistory,
    setCoins,
    setCash,
    setCreditHistory,
} = Payment.actions;

export default Payment.reducer;
