import { createSlice } from '@reduxjs/toolkit';
import {
  createTagsAPI,
  deleteTagsAPI,
  getTagsListAPI,
  retrieveTagsAPI,
  updateTagsAPI,
} from 'src/service/TagsService.js';

const initialState = {
  createModalOpen: false,
  allTags: [],
  validationErrors: {},
};

export const TagsManager = createSlice({
  name: 'Tags',
  initialState,
  reducers: {
    openCreateModal: (state) => {
      state.createModalOpen = true;
    },
    closeCreateModal: (state) => {
      state.createModalOpen = false;
    },
    setAllTags: (state, action) => {
      state.allTags = action.payload;
    },
    setValidationErrors: (state, action) => {
      state.validationErrors = action.payload;
    },
    clearValidationErrors: (state) => {
      state.validationErrors = {};
    },
    addTags: (state, action) => {
      state.allTags = [action.payload, ...state.allTags];
    },
    updateTags: (state, action) => {
      // Below code is to update the question in the array of questions
      const index = state.allTags.findIndex((obj) => obj.id === action.payload?.data?.id);
      state.allTags = [...state.allTags.slice(0, index), action.payload?.data, ...state.allTags.slice(index + 1)];
    },

    deleteTags: (state, action) => {
      const index = action.payload;
      state.allTags.splice(index, 1);
    },
  },
});

export const {
  openCreateModal,
  closeCreateModal,
  setAllTags,
  setValidationErrors,
  clearValidationErrors,
  addTags,
  updateTags,
  deleteTags,
} = TagsManager.actions;

export const getTagsListAction = (param) => async (dispatch) => getTagsListAPI(param);

export const retrieveTagsAction = (id) => async (dispatch) => retrieveTagsAPI(id);

export const createTagsAction = (param) => async (dispatch) => createTagsAPI(param);

export const updateTagsAction = (param) => async (dispatch) => updateTagsAPI(param);

export const deleteTagsAction = (id) => async (dispatch) => deleteTagsAPI(id);

export default TagsManager.reducer;
