import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    otp: '',
    status: 'idle',
    error: null,
    data: {},
    userData: {},
    isOTPSent: false,
    email: '',
    requestType: -1,
    signup: 0
};

export const AuthSlice = createSlice({
    name: 'OTP',
    initialState,

    reducers: {
        setRequestType: (state, action) => {
            state.requestType = action.payload;
            state.signup = 1
        },
        setOTP: (state, action) => {
            state.otp = action.payload;
        },
        getBackRequestType: (state, action) => {
            state.signup = 0;
        },
        setEmail: (state, action) => {
            state.email = action.payload;
            //

        },
        otpSendSuccess: (state, action) => {
            //
            state.isOTPSent = true;
            state.data = { ...action.payload }
            //

        },
        resetOtpSession: (state, action) => {
            //
            state.data = {};
            state.isOTPSent = false;
        },
        otpSendFailure: (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        },
        otpResendSuccess: (state) => {
            state.status = 'succeeded';
            state.error = null;
        },
        otpResendFailure: (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        },
        otpVerificationSuccess: (state) => {
            state.status = 'succeeded';
            state.error = null;
        },
        otpVerificationFailure: (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        },
        setUserData: (state, action) => {
            state.userData = action.payload;

            // Update the data field with the received user data
        },
    },
});

export const {
    setOTP,
    otpSendSuccess,
    otpSendFailure,
    otpResendSuccess,
    otpResendFailure,
    otpVerificationSuccess,
    otpVerificationFailure,
    setEmail,
    resetOtpSession,
    setRequestType,
    getBackRequestType,
    setUserData,
} = AuthSlice.actions;

export default AuthSlice.reducer;
