import { createSlice } from '@reduxjs/toolkit';
import { createQuestionListAPI } from 'src/service/QuestionService';

const initialState = {
    selectedType: 'Theory',
    classType: '',
    visibilityType: '',
    dificultyType: '',
    statusType: '',
    answerLengthType: '',
    allotedTimeType: '',
    options: [{ text: '', editorContent: '' }],
    rows: [],
    selectedBoards: [],
    selectedBooks: [],
    selectedTopic: [],
    selectedSub_Topic: [],
    selectedTags: [],
    selectedSource: [],
    selectedPrice: [],
    selectedBloom: [],




    // The array to store the selected options


};

const createNewQuestionSlice = createSlice({
    name: 'createNewQuestion',
    initialState,
    reducers: {
        setSelectedType: (state, action) => {
            state.selectedType = action.payload;
        },
        setClassType: (state, action) => {
            state.classType = action.payload;
        },


        setStatusType: (state, action) => {
            state.statusType = action.payload;
        },
        setAnswerLengthType: (state, action) => {
            state.answerLengthType = action.payload;
        },
        setAllotedTimeType: (state, action) => {
            state.allotedTimeType = action.payload;
        },
        setOptions: (state, action) => {
            state.options = action.payload;
        },
        addOption: (state, action) => {
            state.options.push(action.payload);
        },
        removeOption: (state, action) => {
            const index = action.payload;
            state.options.splice(index, 1);
        },
        questionAdd: (state, action) => {
            state.rows.push(action.payload);
        },
        setBoards: (state, action) => {
            state.selectedBoards = action.payload;
        },
        setBooks: (state, action) => {
            state.selectedBooks = action.payload;
        },
        setVisibilityType: (state, action) => {
            state.visibilityType = action.payload;
        },
        setDificultyType: (state, action) => {
            state.dificultyType = action.payload;
        },
        setSelectedTopic: (state, action) => {
            state.selectedTopic = action.payload;
        },
        setSelectedSub_Topic: (state, action) => {
            state.selectedSub_Topic = action.payload;
        },
        setSelectedTags: (state, action) => {
            state.selectedTags = action.payload;
        },
        setSelectedSource: (state, action) => {
            state.selectedSource = action.payload;
        },
        setSelectedPrice: (state, action) => {
            state.selectedPrice = action.payload;
        },
        setSelectedBloom: (state, action) => {
            state.selectedBloom = action.payload;
        },


        // Add other actions as needed
    },
});

// Export the actions
export const {
    setBoards,
    setBooks,
    setSelectedType,
    setDificultyType,
    setClassType,
    setVisibilityType,
    setStatusType,
    setAnswerLengthType,
    setAllotedTimeType,

    setOptions,
    addOption,
    removeOption,
    questionAdd,
    setSelectedTopic,
    setSelectedSub_Topic, setSelectedTags, setSelectedSource, setSelectedBloom, setSelectedPrice


    // Add other actions as needed
} = createNewQuestionSlice.actions;

// Export the reducer
export default createNewQuestionSlice.reducer;

export const createQuestion = (param) => async (dispatch) => {
    // Deep clone the param object to remove circular references
    const clonedParam = JSON.parse(JSON.stringify(param));
    return createQuestionListAPI(clonedParam);
};