import { createSlice } from '@reduxjs/toolkit';
import { createTournamentGeneratorAPI, getAttemptedTournamentListAPI, getTournamentAttemptListAPI, getTournamentListAPI, getTournamentQuestions, retrieveTournamentPaperAPI, StartTournamentAPI, TournamentCashDistributeAPI, TournamentRewardCashDistributeAPI, updateTournamentGeneratorAPI } from 'src/service/TournamentService';


const initialState = {
    // Admin Tournament
    form_data: {},
    questions: {},
    pikedQuestion: [],
    Tournament_Data: {},
    // Student Tournament
    allTournamentList: [],
    attemptedTournament: [],
    allAttemptedTournament: [],
    winners: []
}
export const TournamentSlice = createSlice({
    name: 'Tournament',
    initialState,
    reducers: {
        setForm_data: (state, action) => {
            state.form_data = action?.payload
        },
        setQuestion: (state, action) => {
            state.questions = action?.payload
        },
        setPikedQuestion: (state, action) => {
            state.pikedQuestion = action?.payload
        },
        setTournamentData: (state, action) => {
            state.Tournament_Data = action?.payload
        },
        setAllTournamentList: (state, action) => {
            state.allTournamentList = action?.payload
        },
        setAllAttemptedTournamentList: (state, action) => {
            state.allAttemptedTournament = action?.payload
        },
        setAttemptedTournament: (state, action) => {
            state.attemptedTournament = action?.payload
        },
        setWinners: (state, action) => {
            state.winners = action?.payload
        },
    }
})

export default TournamentSlice.reducer;
export const { setForm_data, setQuestion, setPikedQuestion, setAllTournamentList, setAttemptedTournament, setTournamentData, setWinners, setAllAttemptedTournamentList } = TournamentSlice.actions;


export const getTournamentQuestionsAction = (param) => async (dispatch) => getTournamentQuestions(param);
export const createTournamentGeneratorAction = (param) => async (dispatch) => createTournamentGeneratorAPI(param);
export const getTournamentListAction = (param) => async (dispatch) => getTournamentListAPI(param);
export const updateTournamentGeneratorAction = (param) => async (dispatch) => updateTournamentGeneratorAPI(param);
export const getAttemptedTournamentListAction = (param) => async (dispatch) => getAttemptedTournamentListAPI(param);
export const getTournamentAttemptListAction = (id) => async (dispatch) => getTournamentAttemptListAPI(id);
export const startTournamentAPIAction = (param) => async (dispatch) => StartTournamentAPI(param);
export const retrieveTournamentPaperAction = (id) => async (dispatch) => retrieveTournamentPaperAPI(id);
export const TournamentCashDistributeAction = (id) => async (dispatch) => TournamentCashDistributeAPI(id);
export const TournamentRewardCashDistributeAction = (param) => async (dispatch) => TournamentRewardCashDistributeAPI(param);
