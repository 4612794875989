
import { createSlice } from '@reduxjs/toolkit';
import { getBullsAnalysisAPI } from 'src/service/BullEyeAnalyisService';

const initialState = {
    AnalysisData: {},
    leaderBoardData: [],
}
export const BullsEyeSlice = createSlice({
    name: 'BullsEye',
    initialState,
    reducers: {
        setAnalysisResponse: (state, action) => {
            state.AnalysisData = action?.payload
        },
        setBullEyeLeaderBoardData: (state, action) => {
            state.leaderBoardData = action.payload;
        },
    }
})

export const { setAnalysisResponse, setBullEyeLeaderBoardData } = BullsEyeSlice.actions;
export default BullsEyeSlice.reducer;
export const getBullsAnalysisAction = (id) => async (dispatch) => getBullsAnalysisAPI(id);