import { createSlice } from '@reduxjs/toolkit';
import {
  createStandardAPI,
  deleteStandardAPI,
  getStandardListAPI,
  retrieveStandardAPI,
  updateStandardAPI,
} from 'src/service/StandardService.js';

const initialState = {
  createModalOpen: false,
  allStandards: [],
  validationErrors: {},
};

export const StandardManager = createSlice({
  name: 'Standard',
  initialState,
  reducers: {
    openCreateModal: (state) => {
      state.createModalOpen = true;
    },
    closeCreateModal: (state) => {
      state.createModalOpen = false;
    },
    setAllStandards: (state, action) => {
      state.allStandards = action.payload;
    },
    setValidationErrors: (state, action) => {
      state.validationErrors = action.payload;
    },
    clearValidationErrors: (state) => {
      state.validationErrors = {};
    },
    addStandard: (state, action) => {
      state.allStandards = [action.payload, ...state.allStandards];
    },
    updateStandard: (state, action) => {
      // Below code is to update the question in the array of questions
      const index = state.allStandards.findIndex((obj) => obj.id === action.payload?.data?.id);
      state.allStandards = [...state.allStandards.slice(0, index), action.payload?.data, ...state.allStandards.slice(index + 1)];
    },
    deleteStandard: (state, action) => {
      const index = action.payload;
      state.allStandards.splice(index, 1);
    },
  },
});

export const {
  openCreateModal,
  closeCreateModal,
  setAllStandards,
  setValidationErrors,
  clearValidationErrors,
  addStandard,
  updateStandard,
  deleteStandard,
} = StandardManager.actions;

export const getStandardListAction = (param) => async (dispatch) => getStandardListAPI(param);

export const retrieveStandardAction = (id) => async (dispatch) => retrieveStandardAPI(id);

export const createStandardAction = (param) => async (dispatch) => createStandardAPI(param);

export const updateStandardAction = (param) => async (dispatch) => updateStandardAPI(param);

export const deleteStandardAction = (id) => async (dispatch) => deleteStandardAPI(id);

export default StandardManager.reducer;
