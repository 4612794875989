import { createSlice } from '@reduxjs/toolkit';
import { map } from 'lodash';

const initialState = {
	posts: [],
	activeSection: 0,
	workSheetQuestions: [],
};

export const ExamSlice = createSlice({
	name: 'UserPost',
	initialState,
	reducers: {
		getWorkSheet: (state, action) => {
			state.workSheetQuestions = action.payload;
		},
		setSection: (state, action) => {
			state.activeSection = action.payload;
		},

		onToggleFollow(state, action) {
			const followerId = action.payload;

			const handleToggle = map(state.followers, (follower) => {
				if (follower.id === followerId) {
					return {
						...follower,
						isFollowed: !follower.isFollowed,
					};
				}
				return follower;
			});

			state.followers = handleToggle;
		},
	},
});

export const { getWorkSheet, setSection } = ExamSlice.actions;
export default ExamSlice.reducer;
