import { createSlice } from '@reduxjs/toolkit';
import { ExicelDownloadAPI, getSpecialPaperListAPI, LeaderBoardSpecialAPI, retrieveSpecialPaperMetaDataAPI } from 'src/service/SpecialPaperService';

const initialState = {
    paperList: [],
    leaderBoardData: []
};

const SpecialPaperReducer = createSlice({

    name: "SpecialPaper1",
    initialState: initialState,
    reducers: {
        setSepicalpaperList: (state, action) => {
            state.paperList = action.payload;
        },
        setSepecialleaderBoardData: (state, action) => {

            state.leaderBoardData = action.payload;
        },
    }
});

export const { setSepicalpaperList, setSepecialleaderBoardData } = SpecialPaperReducer.actions;

export default SpecialPaperReducer.reducer;

export const getSpecialPaperListAction = (param) => async (dispatch) => getSpecialPaperListAPI(param);
export const LeaderBoardSpecialAction = (param) => async (dispatch) => LeaderBoardSpecialAPI(param);
export const ExicelDownloadAPIAction = (param) => async (dispatch) => ExicelDownloadAPI(param);
export const RetrieveSpecialPaperMetaDataAPIAction = (id) => async (dispatch) => retrieveSpecialPaperMetaDataAPI(id);


