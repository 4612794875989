import { createSlice } from '@reduxjs/toolkit';
import {
  createChapterAPI,
  deleteChapterAPI,
  getChapterListAPI,
  retrieveChapterAPI,
  updateChapterAPI,
} from 'src/service/ChapterService.js';

const initialState = {
  createModalOpen: false,
  allChapters: [],
  validationErrors: {},
};

export const ChapterManger = createSlice({
  name: 'Chapter',
  initialState,
  reducers: {
    openCreateModal: (state) => {
      state.createModalOpen = true;
    },
    closeCreateModal: (state) => {
      state.createModalOpen = false;
    },
    setAllChapters: (state, action) => {
      state.allChapters = action.payload;
    },
    setValidationErrors: (state, action) => {
      state.validationErrors = action.payload;
    },
    clearValidationErrors: (state) => {
      state.validationErrors = {};
    },
    addChapter: (state, action) => {
      state.allChapters = [action.payload, ...state.allChapters];
    },
    updateChapter: (state, action) => {

      // Below code is to update the question in the array of questions
      const index = state.allChapters.findIndex((obj) => obj.id === action.payload?.data?.id);
      state.allChapters = [...state.allChapters.slice(0, index), action.payload?.data, ...state.allChapters.slice(index + 1)];
    },
    deleteChapter: (state, action) => {
      const index = action.payload;
      state.allChapters.splice(index, 1);
    },
  },
});

export const {
  openCreateModal,
  closeCreateModal,
  setAllChapters,
  setValidationErrors,
  clearValidationErrors,
  addChapter,
  updateChapter,
  deleteChapter,
} = ChapterManger.actions;

export const getChapterListAction = (param) => async (dispatch) => getChapterListAPI(param);

export const retrieveChapterAction = (id) => async (dispatch) => retrieveChapterAPI(id);

export const createChapterAction = (param) => async (dispatch) => createChapterAPI(param);

export const updateChapterAction = (param) => async (dispatch) => updateChapterAPI(param);

export const deleteChapterAction = (id) => async (dispatch) => deleteChapterAPI(id);

export default ChapterManger.reducer;
