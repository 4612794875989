import axios from 'axios';
import { REACT_APP_BACKEND_URL } from 'src/utils/constant';

export const isValidToken = async (accessToken) => {
  if (!accessToken) {
    return false;
  }
  let headersList = {
    Accept: '*/*',
    'Content-Type': 'application/json',
  };
  let bodyContent = JSON.stringify({
    token: accessToken,
  });
  let reqOptions = {
    url: `${REACT_APP_BACKEND_URL}/auth/verify-token`,
    method: 'POST',
    headers: headersList,
    data: bodyContent,
  };
  let response = await axios.request(reqOptions);
  return response;
};

export const getUserData = async (accessToken) => {
  let headersList = {
    Accept: '*/*',
    // "Authorization": `Bearer ${accessToken}`
  };
  let reqOptions = {
    url: `${REACT_APP_BACKEND_URL}/user-data/`,
    method: 'GET',
    headers: headersList,
  };
  const response = await axios.request(reqOptions);
  return response;
};
export const setSession = (userData) => {
  if (userData?.access) {
    localStorage.setItem('userData', JSON.stringify(userData));
    axios.defaults.headers.common.Authorization = `Bearer ${userData?.access}`;
  } else {
    localStorage.removeItem('userData');
    delete axios.defaults.headers.common.Authorization;
  }
};
export const setUserSession = (userData) => {
  if (userData) {
    localStorage.setItem('userdata', userData);
  } else {
    localStorage.removeItem('userdata');
  }
};

export const resendOTP = (otp_user_id) => async (dispatch) => {
  let headersList = {
    Accept: '*/*',
    'Content-Type': 'application/json',
  };
  let bodyContent = JSON.stringify({
    otp_user_id,
  });
  let reqOptions = {
    url: `${REACT_APP_BACKEND_URL}/auth/otp/regenerate-otp/`,
    method: 'POST',
    headers: headersList,
    data: bodyContent,
  };
  const response = await axios.request(reqOptions);
  return response;
};
