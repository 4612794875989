import { createSlice } from '@reduxjs/toolkit';
import {
  createSubjectAPI,
  deleteSubjectAPI,
  getSubjectListAPI,
  retrieveSubjectAPI,
  updateSubjectAPI,
} from 'src/service/SubjectService.js';

const initialState = {
  createModalOpen: false,
  allSubjects: [],
  validationErrors: {},
};

export const SubjectManager = createSlice({
  name: 'Subject',
  initialState,
  reducers: {
    openCreateModal: (state) => {
      state.createModalOpen = true;
    },
    closeCreateModal: (state) => {
      state.createModalOpen = false;
    },
    setAllSubjects: (state, action) => {
      state.allSubjects = action.payload;
    },
    setValidationErrors: (state, action) => {
      state.validationErrors = action.payload;
    },
    clearValidationErrors: (state) => {
      state.validationErrors = {};
    },
    addSubject: (state, action) => {
      state.allSubjects = [action.payload, ...state.allSubjects];
    },
    updateSubject: (state, action) => {
      // Below code is to update the question in the array of questions
      const index = state.allSubjects.findIndex((obj) => obj.id === action.payload?.data?.id);
      state.allSubjects = [...state.allSubjects.slice(0, index), action.payload?.data, ...state.allSubjects.slice(index + 1)];
    },
    deleteSubject: (state, action) => {
      const index = action.payload;
      state.allSubjects.splice(index, 1);
    },
  },
});

export const {
  openCreateModal,
  closeCreateModal,
  setAllSubjects,
  setValidationErrors,
  clearValidationErrors,
  addSubject,
  updateSubject,
  deleteSubject,
} = SubjectManager.actions;

export const getSubjectListAction = (param) => async (dispatch) => getSubjectListAPI(param);

export const retrieveSubjectAction = (id) => async (dispatch) => retrieveSubjectAPI(id);

export const createSubjectAction = (param) => async (dispatch) => createSubjectAPI(param);

export const updateSubjectAction = (param) => async (dispatch) => updateSubjectAPI(param);

export const deleteSubjectAction = (id) => async (dispatch) => deleteSubjectAPI(id);

export default SubjectManager.reducer;
