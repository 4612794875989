import { Dialog, DialogContent } from '@mui/material';

import ProfileUpdateTab from 'src/views/template/pages/account-setting/ProfileUpdateTab';


const ProfileFormPopup = ({ isOpen, handleClose }) => {
    return (
        <Dialog open={isOpen} onClose={handleClose} maxWidth="sm">
            {/* <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton> */}
            <DialogContent>
                {/* <ProfileForm handleClose={handleClose} /> */}
                <ProfileUpdateTab handleClose={handleClose} />
            </DialogContent>
        </Dialog>
    );
};

export default ProfileFormPopup;
