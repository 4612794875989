import { createSlice } from '@reduxjs/toolkit';
import {
    createInstructionAPI,
    deleteInstructionAPI,
    getInstructionListAPI,
    retrieveInstructionAPI,
    updateInstructionAPI,
} from 'src/service/InstructionService.js';

const initialState = {
    createModalOpen: false,
    allInstructions: [],
    validationErrors: {},
    instructionHeaderText: '',
    instructionFooterText: '',
};

export const InstructionManger = createSlice({
    name: 'Instruction',
    initialState,
    reducers: {
        openCreateModal: (state) => {
            state.createModalOpen = true;
        },
        closeCreateModal: (state) => {
            state.createModalOpen = false;
        },
        setAllInstructions: (state, action) => {
            state.allInstructions = action.payload;
        },
        setValidationErrors: (state, action) => {
            state.validationErrors = action.payload;
        },
        clearValidationErrors: (state) => {
            state.validationErrors = {};
        },
        addInstruction: (state, action) => {
            state.allInstructions = [action.payload, ...state.allInstructions];
        },
        updateInstruction: (state, action) => {
            // Below code is to update the Instruction in the array of Instructions

            const index = state.allInstructions.findIndex((obj) => obj.id === action.payload?.data?.id);

            state.allInstructions = [...state.allInstructions.slice(0, index), action.payload?.data, ...state.allInstructions.slice(index + 1)];
        },
        deleteInstruction: (state, action) => {
            const index = action.payload;
            state.allInstructions.splice(index, 1);
        },
        setInstructionText: (state, action) => {
            if (action.payload.type === "header")
                state.instructionHeaderText = action.payload?.payload;
            else
                state.instructionFooterText = action.payload?.payload;
        },
    },
});

export const {
    openCreateModal,
    closeCreateModal,
    setAllInstructions,
    setValidationErrors,
    clearValidationErrors,
    addInstruction,
    updateInstruction,
    deleteInstruction,
    setInstructionText,
} = InstructionManger.actions;

export const getInstructionListAction = (param) => async (dispatch) => getInstructionListAPI(param);

export const retrieveInstructionAction = (id) => async (dispatch) => retrieveInstructionAPI(id);

export const createInstructionAction = (param) => async (dispatch) => createInstructionAPI(param);

export const updateInstructionAction = (param) => async (dispatch) => updateInstructionAPI(param);

export const deleteInstructionAction = (id) => async (dispatch) => deleteInstructionAPI(id);

export default InstructionManger.reducer;
