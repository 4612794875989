import { createSlice } from '@reduxjs/toolkit';
import { sendNotificationAPI } from 'src/service/AdminFunctionService';

const initialState = {
};

export const AdminFunctionManager = createSlice({
  name: 'AdminFunction',
  initialState,
  reducers: {

  },
});

export const {
} = AdminFunctionManager.actions;

export const sendNotificationAction = (param) => async (dispatch) => sendNotificationAPI(param);

export default AdminFunctionManager.reducer;
