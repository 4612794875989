import axios from 'axios';
import { REACT_APP_BACKEND_URL } from 'src/utils/constant';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.patch['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';

let headersList = { Accept: '*/*' };


export const getKycListAPI = async (url) => {
    const response = await axios.get(url ?? `${REACT_APP_BACKEND_URL}/kyc/`);
    return response;
};


// export const retrieveSectionAPI = async (id) => {
//     const reqOptions = {
//         url: `${REACT_APP_BACKEND_URL}/api/section/${id}/`,
//         method: 'GET',
//         headers: headersList,
//     };
//     const response = await axios.request(reqOptions);
//     return response;
// };


//to a perticular section for a specific paper 

export const createKycAPI = async (param) => {
    const reqOptions = {
        url: `${REACT_APP_BACKEND_URL}/kyc/`,
        method: 'POST',
        headers: headersList,
        data: JSON.stringify({ ...param }),
    };

    const response = await axios.request(reqOptions);
    return response;
};



//to update a perticular section

export const updateKycAPI = async (param) => {
    const { id, values } = param;
    let reqOptions = {
        url: `${REACT_APP_BACKEND_URL}/kyc/${id}/`,
        method: 'PATCH',
        headers: headersList,
        data: JSON.stringify({ ...values }),
    };

    return await axios.request(reqOptions);
};

//to delete a perticular section

export const deleteSectionAPI = async (param) => {
    const { id } = param;
    let reqOptions = {
        url: `${REACT_APP_BACKEND_URL}/api/section/${id}/`,
        method: 'DELETE',
        headers: headersList,
    };
    return await axios.request(reqOptions);
};