import { useTheme } from '@mui/material';
import { createTheme } from "@mui/material";
import { enqueueSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';

export function useUserTableTheme() {
	const globalTheme = useTheme();

	const tableTheme = useMemo(
		() =>
			createTheme({
				palette: {
					mode: globalTheme.palette.mode,
					primary: globalTheme.palette.primary,
					secondary: globalTheme.palette.secondary,
					info: {
						main: 'rgb(255,122,0)',
					},
					background: {
						default:
							globalTheme.palette.mode === 'light'
								? '#fff'
								: '#000',
					},
				},
				typography: {
					button: {
						textTransform: 'none',
						fontSize: '1.2rem',
					},
				},
				components: {
					MuiTooltip: {
						styleOverrides: {
							tooltip: {
								fontSize: '1.1rem',
							},
						},
					},
				},
			}),
		[globalTheme]
	);

	return tableTheme;
}


export function ErrorNotification(response) {
	const { data, status } = response
	if (data === undefined || status >= 500) {
		enqueueSnackbar("Something went wrong. Please contact to Admin.", { variant: 'error', autoHideDuration: 10000 })
		return true
	}
	if (typeof data === 'object') {
		Object.values(data).forEach((value) => {
			enqueueSnackbar(value, { variant: 'error', autoHideDuration: 10000 })
		});
	} else {
		enqueueSnackbar(data, { variant: 'error', autoHideDuration: 10000 })
	}
	return false
}

