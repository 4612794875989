import {
  Avatar,
  Button,
  Grid
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useRef, useState } from "react";
import useAuth from "src/guards/authGuard/UseAuth";

import "./style.css";

function ImageUpload({ setIsDialogOpen, getAvatarUrl }) {
  const { updateUserLogo, avatar, user } = useAuth();
  const [image, setImage] = useState(null);
  const [file, setFile] = useState(null);
  const hiddenFileInput = useRef(null);
  const [isImageSelected, setIsImageSelected] = useState(false);
  const [avatarURL, setAvatarURL] = useState(avatar);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setAvatarURL(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUploadButtonClick = async () => {
    const response = await updateUserLogo(file);
    if (response) {
      setIsDialogOpen(false);
      enqueueSnackbar('Logo uploaded Successful!', { variant: 'success' });
    } else {
      enqueueSnackbar('Error in uploading logo!', { variant: 'error' });
    }
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  return (
    <Grid container direction="column" alignItems="center" justifyContent="center" sx={{ width: "420px", height: "350px" }}>
      <Grid item>
        <Avatar src={avatarURL || "../../assets/images/profile/user-1.jpg"} sx={{ width: 200, height: 200 }} />
      </Grid>

      {!isImageSelected ? (
        <Grid item sx={{ margin: "25px" }}>
          <Button color="primary" component="label">
            Select avatar
            <input
              hidden
              accept="image/*"
              type="file"
              onChange={(e) => {
                handleImageUpload(e);
                setIsImageSelected(true);
              }}
            />
          </Button>
        </Grid>
      ) : (
        <>
          <Grid item sx={{ margin: "25px" }}>
            <Button color="primary" size="medium" onClick={handleUploadButtonClick} sx={{ marginRight: "15px" }}>
              Upload image
            </Button>

            <Button size="medium" onClick={() => { setIsImageSelected(false); setAvatarURL(avatar) }} color="secondary">
              Cancel
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default ImageUpload;
