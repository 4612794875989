import { createSlice } from '@reduxjs/toolkit';
import {
  createBloomTaxonomyAPI,
  deleteBloomTaxonomyAPI,
  getBloomTaxonomyListAPI,
  retrieveBloomTaxonomyAPI,
  updateBloomTaxonomyAPI,
} from 'src/service/BloomtaxonomyService';

const initialState = {
  createModalOpen: false,
  allBloomTaxonomy: [],
  validationErrors: {},
};

export const BloomtaxonomyManager = createSlice({
  name: 'Bloom',
  initialState,
  reducers: {
    openCreateModal: (state) => {
      state.createModalOpen = true;
    },
    closeCreateModal: (state) => {
      state.createModalOpen = false;
    },
    setAllBloomTaxonomy: (state, action) => {
      state.allBloomTaxonomy = action.payload;
    },
    setValidationErrors: (state, action) => {
      state.validationErrors = action.payload;
    },
    clearValidationErrors: (state) => {
      state.validationErrors = {};
    },
    addBloom: (state, action) => {
      state.allBloomTaxonomy = [action.payload, ...state.allBloomTaxonomy];
    },
    updateBloom: (state, action) => {
      // Below code is to update the question in the array of questions
      const index = state.allBloomTaxonomy.findIndex((obj) => obj.id === action.payload?.data?.id);
      state.allBloomTaxonomy = [...state.allBloomTaxonomy.slice(0, index), action.payload?.data, ...state.allBloomTaxonomy.slice(index + 1)];
    },
    deleteBloom: (state, action) => {
      const index = action.payload;
      state.allBloomTaxonomy.splice(index, 1);
    },
  },
});

export const {
  openCreateModal,
  closeCreateModal,
  setAllBloomTaxonomy,
  setValidationErrors,
  clearValidationErrors,
  addBloom,
  updateBloom,
  deleteBloom,
} = BloomtaxonomyManager.actions;

export const getBloomTaxonomyListAction = (param) => async (dispatch) => getBloomTaxonomyListAPI(param);

export const retrieveBloomTaxonomyAction = (id) => async (dispatch) => retrieveBloomTaxonomyAPI(id);

export const createBloomTaxonomyAction = (param) => async (dispatch) => createBloomTaxonomyAPI(param);

export const updateBloomTaxonomyAction = (param) => async (dispatch) => updateBloomTaxonomyAPI(param);

export const deleteBloomTaxonomyAction = (id) => async (dispatch) => deleteBloomTaxonomyAPI(id);

export default BloomtaxonomyManager.reducer;
