import axios from 'axios';
import { REACT_APP_BACKEND_URL } from 'src/utils/constant';
axios.defaults.headers.post['Content-Type'] = 'application/json';

let headersList = { Accept: '*/*' };

export const getQuestionsListAPi = async (url) => {
  const response = await axios.get(url ?? `${REACT_APP_BACKEND_URL}/question`);
  return response;
};


export const retrieveQuestionListAPI = async ({ id }) => {
  const reqOptions = {
    url: `${REACT_APP_BACKEND_URL}/question/${id}/`,
    method: 'GET',
    headers: headersList,
  };
  const response = await axios.request(reqOptions);
  return response;
};

const detectCircularReference = (obj, seenObjects = new Set()) => {
  if (typeof obj === "object" && obj !== null) {
    if (seenObjects.has(obj)) {
      return true; // Circular reference found
    }
    seenObjects.add(obj);
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        if (detectCircularReference(obj[key], seenObjects)) {
          return true;
        }
      }
    }
    seenObjects.delete(obj);
  }
  return false;
};

export const createQuestionListAPI = async (pram) => {

  const reqOptions = {
    url: `${REACT_APP_BACKEND_URL}/question/`,
    method: 'POST',
    headers: headersList,
    data: JSON.stringify(pram),
  };
  const response = await axios.request(reqOptions);
  return response;
};

export const updateQuestionListAPI = async ({ id, pram }) => {
  //
  //

  let reqOptions = {
    url: `${REACT_APP_BACKEND_URL}/question/${id}/`,
    method: 'Patch',
    headers: headersList,
    data: JSON.stringify(pram),
  };
  const response = await axios.request(reqOptions);
  //
  return response;
};
export const deleteQuestionListAPI = async ({ id }) => {
  let reqOptions = {
    url: `${REACT_APP_BACKEND_URL}/question/${id}/`,
    method: 'Delete',
    headers: headersList,
  };
  const response = await axios.request(reqOptions);
  return response;
};

export const createNewQuestionListAPI = async (dispatch, values, rows, setRows) => {
  try {
    // Make a request to the API to save the new question
    const response = await axios.post('/question', values);
    const newQuestion = response.data;

    // Update the rows state with the new question
    setRows([...rows, newQuestion]);
  } catch (error) {
    console.error('Error creating new question:', error);
  }
};

export const saveRowEditsListAPI = async (dispatch, values, rows, setRows, row) => {
  try {
    // Make a request to the API to update the question
    const response = await axios.put(`${REACT_APP_BACKEND_URL}/question/${row.original.id}`, values);

    if (response.status === 200) {
      // Update the rows state with the edited question
      const updatedRows = [...rows];
      updatedRows[row.index] = values;
      setRows(updatedRows);
    } else {
      console.error('Error saving row edits:', response);
    }
  } catch (error) {
    console.error('Error saving row edits:', error);
  }
};

export const deleteRowListAPI = async (dispatch, row, rows, setRows) => {
  if (window.confirm(`Are you sure you want to delete ${row.original.Tittle}?`)) {
    try {
      // Make a request to the API to delete the question
      await axios.delete(`${REACT_APP_BACKEND_URL}/question/${row.original.id}`);

      // Update the rows state by removing the deleted question
      const updatedRows = rows.filter((_, index) => index !== row.index);
      setRows(updatedRows);
    } catch (error) {
      console.error('Error deleting row:', error);
    }
  }
};
