import { lazy } from 'react';

import Loadable from '../layouts/full/shared/loadable/Loadable';

/* ****Pages***** */
const SamplePage = Loadable(lazy(() => import('../views/template/sample-page/SamplePage')));

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank/BlankLayout')));

/* ****Pages***** */
const ModernDash = Loadable(lazy(() => import('../views/template/dashboard/Modern')));
const EcommerceDash = Loadable(lazy(() => import('../views/template/dashboard/Ecommerce')));

/* ****Apps***** */
const Chats = Loadable(lazy(() => import('../views/template/apps/chat/Chat')));
const Notes = Loadable(lazy(() => import('../views/template/apps/notes/Notes')));
const Calendar = Loadable(lazy(() => import('../views/template/apps/calendar/BigCalendar')));
const Email = Loadable(lazy(() => import('../views/template/apps/email/Email')));
const Blog = Loadable(lazy(() => import('../views/template/apps/blog/Blog')));
const BlogDetail = Loadable(lazy(() => import('../views/template/apps/blog/BlogPost')));
const Tickets = Loadable(lazy(() => import('../views/template/apps/tickets/Tickets')));
const Contacts = Loadable(lazy(() => import('../views/template/apps/contacts/Contacts')));
const Ecommerce = Loadable(lazy(() => import('../views/template/apps/eCommerce/Ecommerce')));
const EcommerceDetail = Loadable(lazy(() => import('../views/template/apps/eCommerce/EcommerceDetail')));
const EcomProductList = Loadable(lazy(() => import('../views/template/apps/eCommerce/EcomProductList')));
const EcomProductCheckout = Loadable(
  lazy(() => import('../views/template/apps/eCommerce/EcommerceCheckout'))
);
const UserProfile = Loadable(lazy(() => import('../views/template/apps/user-profile/UserProfile')));
const Followers = Loadable(lazy(() => import('../views/template/apps/user-profile/Followers')));
const Friends = Loadable(lazy(() => import('../views/template/apps/user-profile/Friends')));
const Gallery = Loadable(lazy(() => import('../views/template/apps/user-profile/Gallery')));

// Pages
const RollbaseCASL = Loadable(lazy(() => import('../views/template/pages/rollbaseCASL/RollbaseCASL')));
const Treeview = Loadable(lazy(() => import('../views/template/pages/treeview/Treeview')));

const AccountSetting = Loadable(
  lazy(() => import('../views/template/pages/account-setting/AccountSetting'))
);


// widget
const WidgetCards = Loadable(lazy(() => import('../views/template/widgets/cards/WidgetCards')));
const WidgetBanners = Loadable(lazy(() => import('../views/template/widgets/banners/WidgetBanners')));
const WidgetCharts = Loadable(lazy(() => import('../views/template/widgets/charts/WidgetCharts')));

// form elements
const MuiAutoComplete = Loadable(
  lazy(() => import('../views/template/forms/form-elements/MuiAutoComplete'))
);
const MuiButton = Loadable(lazy(() => import('../views/template/forms/form-elements/MuiButton')));
const MuiCheckbox = Loadable(lazy(() => import('../views/template/forms/form-elements/MuiCheckbox')));
const MuiRadio = Loadable(lazy(() => import('../views/template/forms/form-elements/MuiRadio')));
const MuiSlider = Loadable(lazy(() => import('../views/template/forms/form-elements/MuiSlider')));
const MuiDateTime = Loadable(lazy(() => import('../views/template/forms/form-elements/MuiDateTime')));
const MuiSwitch = Loadable(lazy(() => import('../views/template/forms/form-elements/MuiSwitch')));

// form layout
const FormLayouts = Loadable(lazy(() => import('../views/template/forms/FormLayouts')));
const FormCustom = Loadable(lazy(() => import('../views/template/forms/FormCustom')));
const FormWizard = Loadable(lazy(() => import('../views/template/forms/FormWizard')));
const FormValidation = Loadable(lazy(() => import('../views/template/forms/FormValidation')));
const QuillEditor = Loadable(lazy(() => import('../views/template/forms/quill-editor/QuillEditor')));
const FormHorizontal = Loadable(lazy(() => import('../views/template/forms/FormHorizontal')));
const FormVertical = Loadable(lazy(() => import('../views/template/forms/FormVertical')));

// tables
const BasicTable = Loadable(lazy(() => import('../views/template/tables/BasicTable')));
const CollapsibleTable = Loadable(lazy(() => import('../views/template/tables/CollapsibleTable')));
const EnhancedTable = Loadable(lazy(() => import('../views/template/tables/EnhancedTable')));
const FixedHeaderTable = Loadable(lazy(() => import('../views/template/tables/FixedHeaderTable')));
const PaginationTable = Loadable(lazy(() => import('../views/template/tables/PaginationTable')));
const SearchTable = Loadable(lazy(() => import('../views/template/tables/SearchTable')));

// chart
const LineChart = Loadable(lazy(() => import('../views/template/charts/LineChart')));
const GredientChart = Loadable(lazy(() => import('../views/template/charts/GredientChart')));
const DoughnutChart = Loadable(lazy(() => import('../views/template/charts/DoughnutChart')));
const AreaChart = Loadable(lazy(() => import('../views/template/charts/AreaChart')));
const ColumnChart = Loadable(lazy(() => import('../views/template/charts/ColumnChart')));
const CandlestickChart = Loadable(lazy(() => import('../views/template/charts/CandlestickChart')));
const RadialbarChart = Loadable(lazy(() => import('../views/template/charts/RadialbarChart')));

// ui
const MuiAlert = Loadable(lazy(() => import('../views/template/ui-components/MuiAlert')));
const MuiAccordion = Loadable(lazy(() => import('../views/template/ui-components/MuiAccordion')));
const MuiAvatar = Loadable(lazy(() => import('../views/template/ui-components/MuiAvatar')));
const MuiChip = Loadable(lazy(() => import('../views/template/ui-components/MuiChip')));
const MuiDialog = Loadable(lazy(() => import('../views/template/ui-components/MuiDialog')));
const MuiList = Loadable(lazy(() => import('../views/template/ui-components/MuiList')));
const MuiPopover = Loadable(lazy(() => import('../views/template/ui-components/MuiPopover')));
const MuiRating = Loadable(lazy(() => import('../views/template/ui-components/MuiRating')));
const MuiTabs = Loadable(lazy(() => import('../views/template/ui-components/MuiTabs')));
const MuiTooltip = Loadable(lazy(() => import('../views/template/ui-components/MuiTooltip')));
const MuiTransferList = Loadable(lazy(() => import('../views/template/ui-components/MuiTransferList')));
const MuiTypography = Loadable(lazy(() => import('../views/template/ui-components/MuiTypography')));

// authentication
// const Login = Loadable(lazy(() => import('../views/template/authentication/auth1/Login')));
const Login2 = Loadable(lazy(() => import('../views/template/authentication/auth2/Login2')));
const Register = Loadable(lazy(() => import('../views/template/authentication/auth1/Register')));
const Register2 = Loadable(lazy(() => import('../views/template/authentication/auth2/Register2')));
const ForgotPassword = Loadable(lazy(() => import('../views/template/authentication/auth1/ForgotPassword')));
const ForgotPassword2 = Loadable(
  lazy(() => import('../views/template/authentication/auth2/ForgotPassword2'))
);
const TwoSteps = Loadable(lazy(() => import('../views/template/authentication/auth1/TwoSteps')));
const TwoSteps2 = Loadable(lazy(() => import('../views/template/authentication/auth2/TwoSteps2')));
const Error = Loadable(lazy(() => import('../views/template/authentication/Error404')));
const Maintenance = Loadable(lazy(() => import('../views/template/authentication/Maintenance')));

// landingpage
const Landingpage = Loadable(lazy(() => import('../views/template/pages/landingpage/Landingpage')));

export {
  AccountSetting,
  AreaChart,
  BasicTable,
  BlankLayout,
  Blog,
  BlogDetail,
  Calendar,
  CandlestickChart,
  Chats, CollapsibleTable,
  ColumnChart,
  Contacts,
  DoughnutChart, Ecommerce,
  EcommerceDash,
  EcommerceDetail, EcomProductCheckout,
  EcomProductList, Email,
  EnhancedTable,
  Error,
  FixedHeaderTable,
  Followers,
  ForgotPassword,
  ForgotPassword2,
  FormCustom,
  FormHorizontal,
  FormLayouts,
  FormValidation,
  FormVertical,
  FormWizard,
  Friends,
  FullLayout,
  Gallery,
  GredientChart,
  Landingpage,
  LineChart,
  // Login,
  Login2,
  Maintenance,
  ModernDash,
  MuiAccordion,
  MuiAlert,
  MuiAutoComplete,
  MuiAvatar,
  MuiButton,
  MuiCheckbox,
  MuiChip,
  MuiDateTime,
  MuiDialog,
  MuiList,
  MuiPopover,
  MuiRadio,
  MuiRating,
  MuiSlider,
  MuiSwitch,
  MuiTabs,
  MuiTooltip,
  MuiTransferList,
  MuiTypography,
  Notes,
  PaginationTable,
  QuillEditor,
  RadialbarChart,
  Register,
  Register2,
  RollbaseCASL,
  SamplePage,
  SearchTable,
  Tickets,
  Treeview,
  TwoSteps,
  TwoSteps2,
  UserProfile,
  WidgetBanners,
  WidgetCards,
  WidgetCharts
};

