import axios from 'axios';
import { REACT_APP_BACKEND_URL } from 'src/utils/constant';
// import { REACT_APP_BACKEND_URL } from '../utils/constant';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.patch['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';

let headersList = { Accept: '*/*' };

//to a perticular section for a specific paper 
export const getCreditsListAPI = async (url) => {
    const response = await axios.get(url ?? `${REACT_APP_BACKEND_URL}/credits/`);
    return response;
};

export const getCreditsHistoryListAPI = async (url) => {
    const response = await axios.get(url ?? `${REACT_APP_BACKEND_URL}/coins/`);
    return response;
};

export const getTransactionHistoryListAPI = async (url) => {
    const response = await axios.get(url ?? `${REACT_APP_BACKEND_URL}/transaction-history/`);
    return response;
};


export const getRedeemHistoryListAPI = async (url) => {
    const response = await axios.get(url ?? `${REACT_APP_BACKEND_URL}/coin_redeem/`);
    return response;
};


export const createdPurchaseAPI = async (param) => {
    try {
        const reqOptions = {
            url: `${REACT_APP_BACKEND_URL}/purchase-credits/`,
            method: 'POST',
            headers: headersList,
            data: JSON.stringify({ ...param }),
        };

        const response = await axios.request(reqOptions);

        // Return only the relevant data from the response
        return {
            success: true, // Add any success indicator based on your API response
            data: response.data, // Assuming your relevant data is in response.data
        };
    } catch (error) {
        // Handle errors appropriately
        console.error("Error in createdPurchaseAPI:", error);
        throw error;
    }
};

export const PaymentValidationAPI = async (param) => {
    const reqOptions = {
        url: `${REACT_APP_BACKEND_URL}/payment-validation/`,
        method: 'POST',
        headers: headersList,
        data: JSON.stringify({ ...param }),
    };

    const response = await axios.request(reqOptions);
    return response;
};


export const ClaimFriendAPI = async (param) => {
    const reqOptions = {
        url: `${REACT_APP_BACKEND_URL}/referral-claim/`,
        method: 'POST',
        headers: headersList,
        data: JSON.stringify({ ...param }),
    };

    const response = await axios.request(reqOptions);
    return response;
};



export const TransferCoinsAPI = async (pram) => {
    const reqOptions = {
        url: `${REACT_APP_BACKEND_URL}/transfer_coins/`,
        method: 'POST',
        headers: headersList,
        data: JSON.stringify(pram),
    };
    const response = await axios.request(reqOptions);
    return response;
};


export const AdminTransferCoinsAPI = async (pram) => {
    const reqOptions = {
        url: `${REACT_APP_BACKEND_URL}/admin-coin/`,
        method: 'POST',
        headers: headersList,
        data: JSON.stringify(pram),
    };
    const response = await axios.request(reqOptions);
    return response;
};